<template>
  <v-card
    flat
    class="pa-3 mt-2">
    <v-card-text>
      <v-form class="multi-col-validation mt-6">
        <v-row>
          <v-col
            cols="8"
            md="4"
          >
            <v-btn
              color="secondary"
              outlined
              class="mb-4"
              @click="download()"
            >
              <v-icon
                size="17"
                class="me-1"
              >
                {{ icons.mdiExportVariant }}
              </v-icon>
              <span>Export</span>
            </v-btn>
          </v-col>
          <v-spacer></v-spacer>
          <v-col
            cols="8"
            md="4"
          >
            <date-range v-model="filters.dateRange"></date-range>
          </v-col>
          <v-col
            cols="8"
            md="4"
          >
            <v-select
              v-model="filters.branchId"
              :items="branchesDropDown"
              :menu-props="{ maxHeight: '400' }"
              label="Филиалы"
              dense
              outlined
              persistent-hint
              hide-details
            ></v-select>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <v-data-table
      :headers="headers"
      :items="data"
      :loading="loading"
      :server-items-length="totalCount"
      :page.sync="filters.pageNumber"
      :items-per-page.sync="filters.pageSize"
      :sort-by.sync="filters.sortBy"
      :sort-desc.sync="filters.sortDesc"
      class="text-no-wrap"
    >

      <template #[`item.order`]="{item}">
        <v-btn :to="{ name: 'order-form-tab', params: { id: item.order } }"> {{ item.order }}
        </v-btn>
      </template>

      <template #[`item.client`]="{item}">
        {{ item.client }}
      </template>

      <template #[`item.orderSum`]="{item}">
        {{ item.orderSum }}
      </template>

      <template #[`item.amount`]="{item}">
        {{ item.amount }}
      </template>

    </v-data-table>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { mapFormHandlers } from '@/helpers/forms'
import { mdiEye } from '@mdi/js'
import DateRange from '@/components/date-range'

export default {
  name: 'OrderDigitalCurrencyByBranch',
  components: { DateRange },
  data: () => ({
    icons: {
      mdiEye,
    },
  }),
  computed: {
    ...mapFormHandlers('digitalCurrencyByBranch', [
      'filters.dateRange',
      'filters.branchId',
      'filters.pageNumber',
      'filters.pageSize',
      'filters.searchQuery',
      'filters.sortBy',
      'filters.sortDesc',
    ]),
    ...mapGetters('dictionary', { branchesDropDown: 'branchesDropDown' }),
    ...mapState('digitalCurrencyByBranch', {
      data: 'list',
      totalCount: 'totalCount',
      loading: 'loading',
    }),
    headers() {
      return [
        { text: 'Заказ', value: 'order' },
        { text: 'Клиент', value: 'client' },
        { text: 'Оплачено', value: 'amount' },
        { text: 'Сумма заказа', value: 'orderSum' },
      ]
    },
  },
  async created() {
    await this.fetchBranches()
    this.filters.dateRange.start = this.$route.params.start
    this.filters.dateRange.end = this.$route.params.end
    this.filters.branchId = this.$route.params.branchId

    await this.fetchData()
  },
  methods: {
    ...mapActions('digitalCurrencyByBranch', {
      fetchData: 'fetchList',
      download: 'download',
    }),
    ...mapActions('dictionary', { fetchBranches: 'fetchBranches' }),
  },
}
</script>

<style lang="scss" scoped>
#promotion-list {
  .promotion-list-search {
    max-width: 10.625rem;
  }
}
</style>
